/* eslint-disable import/group-exports */

export const theme = "light";

export const colors = {
    light: {
        primary: "#572148",
        secondary: "#FFF",
        tertiary: "#000",
        heading: "#572148",
        subHeading: "#572148",
        text: "#212529",
        background: "#F4F7FD",

        red: "#DC3545", //(Errors, some buttons & other places)
        blue: "#007bff", //links
        white: "#FFFFFF", //containers , starting page background
        borderLightGrey: "#e3e3e3" //borders (most) - tab, input ,side bar button, accordian border etc.
    },

    dark: {
        primary: "#572148",
        secondary: "#000",
        tertiary: "#FFF",
        heading: "#572148",
        subHeading: "#572148",
        text: "#F4F7FD",
        background: "#212529",

        red: "#DC3545", //(Errors, some buttons & other places)
        blue: "#007bff", //links
        white: "#FFFFFF", //containers , starting page background
        borderLightGrey: "#e3e3e3" //borders (most) - tab, input ,side bar button, accordian border etc.
    }
};

export const fonts = {
    light: {
        primary: "Hind",
        secondary: "Nunito",
        tertiary: "Lato",
        headings: "Hind",
        eventCards: "Hind",
        minimalistic: "Hind"
    },
    dark: {
        primary: "Hind",
        secondary: "Nunito",
        tertiary: "Lato",
        headings: "Hind",
        eventCards: "Hind",
        minimalistic: "Hind"
    }
};

export const dashboardColors = {
    light: {
        ...colors.light,
        purple: "#572148", //Main Dashboard theme color
        grey: "#6c757d", //Nav Items, Subtitles, less important texts
        black: "#212529", //text color on AD,
        backgroundWhite: "#F4F7FD", // background of the dashboard pages
        lightWhite: "#f5f5f0", // data table headers
        lightGrey: "#eae5e9", // NavItems
        lightestPurple: "#f1ddec", //Certain areas like Teams page box
        borderGrey: "#00000080", //border - darker border ->some rare places like forms
        lightGreen: "#cfebdd" //attendee count badge background
    },
    dark: {
        ...colors.dark,
        purple: "#572148", //Main Dashboard theme color
        grey: "#6c757d", //Nav Items, Subtitles, less important texts
        black: "#212529", //text color on AD,
        backgroundWhite: "#F4F7FD", // background of the dashboard pages
        lightWhite: "#f5f5f0", // data table headers
        lightGrey: "#eae5e9", // NavItems
        lightestPurple: "#f1ddec", //Certain areas like Teams page box
        borderGrey: "#00000080", //border - darker border ->some rare places like forms
        lightGreen: "#cfebdd" //attendee count badge background
    }
};

export const websiteColors = {
    light: {
        ...colors.light,
        primaryWebsiteTheme: "#002E6E",
        secondaryWebsiteTheme: "#E1F0FF",
        headingColor: "#101828",
        textColor: "#475467",
        staticPagesHeading: "#001234",
        staticPagesLink: "#006ef2",
        staticPagesTableBorder: "#61c0ff",
        dotted: "#BFBFBF",
        customCheckboxBorder: "#D0D5DD",
        customCheckboxBackground: "#fafbfd",
        textParagraph: "#292929",
        textSecondary: "#424242",
        primaryRegistrationTheme: "#002E6E",
        secondaryRegistrationTheme: "#1C3177",
        borderColor: "#D9D9D9",
        headingRegistrationColor: "#525252",
        modalHeading: "#001A47"
    },
    dark: {
        ...colors.dark,
        orange: "#FB5850", //Main 2.0 theme color &  AD buttons
        green: "#20b038", //Email accordian tick icon, live circle on header & Join whatsapp 2.0 button
        black20: "#4F4F4F", //text color on 2.0
        lightGrey2: "#f6f9fc", // 2.0 backgrounds
        lightPurple: "#f3f3f32b" // 2.0 home page accordian section
    }
};

export const dashboardFonts = {
    light: { ...fonts.light },
    dark: { ...fonts.dark }
};

export const websiteFonts = {
    light: {
        ...fonts.light,
        minimalistic: "Montserrat",
        eventCards: "Space Grotesk",
        konfhub: "Caveat Brush",
        konfhubWebsiteHeadings: "Poppins"
    },
    dark: {
        ...fonts.dark,
        minimalistic: "Montserrat",
        eventCards: "Space Grotesk",
        konfhub: "Caveat Brush",
        konfhubWebsiteHeadings: "Poppins"
    }
};